<template>
  <section class="auth">
    <div class="auth-container">
      <div class="auth-form">
        <b-overlay :show="isAuthLoading" spinner-variant="success" rounded="sm" opacity="0.3">
          <div class="text-center mb-2">
           
          </div>
          <h4 class="auth__title">Регистрация</h4>
          <b-tabs @activate-tab="resetFilledData" align="center" content-class="mt-2">
            <b-tab title="Для физ. лиц" active title-link-class="text-dark text-decoration-none">
              <form>
                <b-form-group id="input-group-1" label="Номер телефона:" label-for="input-1">
                  <b-form-input
                    v-model.trim="phone"
                    id="input-1"
                    type="text"
                    placeholder="+7 ("
                    v-mask="'+7 (###) ### ## ##'"
                    required
                    :state="validatePhone"
                    :disabled="isSmsCodeForFastRegSend"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validatePhone">
                    Введите корректный номер телефона
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-show="isSmsCodeForFastRegSend"
                  id="input-group-2"
                  label="Введите код:"
                  label-for="input-2"
                >
                  <b-form-input
                    v-model.trim="smsCode"
                    id="input-2"
                    type="text"
                    placeholder="Введите полученный код"
                    v-mask="'######'"
                    required
                    :state="validateSmsCode"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validateSmsCode">
                    Смс-код должен состоять из 6-цифер
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="mt-4 text-center">
                  <b-button v-if="!isSmsCodeForFastRegSend" @click.prevent="registerSms" variant="success" type="submit"
                    >Получить код
                  </b-button>
                  <b-button v-else @click.prevent="activateAccount(true)" variant="success" type="submit"
                    >Зарегистрироваться
                  </b-button>
                </div>
              </form>
            </b-tab>
            
          </b-tabs>

          <div class="mt-3 text-center font-weight-bold">
            Уже есть аккаунт? <br />
            <router-link :to="{ name: 'auth.login' }" class="text-success">Войти</router-link>
          </div>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>
import { mask } from 'vue-the-mask'
import Api from '@/services/api'

export default {
  name: 'RegistrationPage',
  directives: { mask },
  data: () => ({
    isAuthLoading: false,
    phone: '',
    fullName: '',
    email: '',
    password: '',
    usersPassword: '',
    smsCode: '',
    isSmsCodeForFastRegSend: false,
    isTouched: false,
  }),

  computed: {
    validatePhone() {
      if (!this.isTouched) return null
      return this.phone.length === 18
    },

    validateFullName() {
      if (!this.isTouched) return null
      return this.fullName.length > 5 && this.fullName.includes(' ')
    },

    validatePassword() {
      if (!this.isTouched) return null
      return this.password.length >= 4
    },

    validateUsersPassword() {
      if (!this.isTouched) return null
      return this.usersPassword.length >= 4
    },

    validateSmsCode() {
      if (!this.isTouched) return null
      return this.smsCode.length === 6
    },
  },

  methods: {
    resetFilledData() {
      this.phone = ''
      this.fullName = ''
      this.email = ''
      this.password = ''
      this.usersPassword = ''
      this.smsCode = ''
      this.isSmsCodeForFastRegSend = false
      this.isTouched = false
    },

    async registerSms() {
      this.isTouched = true
      if (!this.validatePhone) return
      try {
        this.isAuthLoading = true
        const phone = this.phone.replace(/[+() ]/g, '')
        await this.wrapAndHandleRequest(() => Api.registerSms({ phone }), false)
        this.isSmsCodeForFastRegSend = true
      } finally {
        this.isTouched = false
        this.isAuthLoading = false
      }
    },

    async fullRegisterSms() {
      this.isTouched = true
      if (!this.validatePhone || !this.validateFullName || !this.validateUsersPassword) return
      try {
        this.isAuthLoading = true
        const phone = this.phone.replace(/[+() ]/g, '')
        const data = {
          phone,
          email: this.email,
          password: this.usersPassword,
          fullname: this.fullName,
        }
        await this.wrapAndHandleRequest(() => Api.fullRegisterSms(data), false)
        this.isSmsCodeForFastRegSend = true
      } finally {
        this.isTouched = false
        this.isAuthLoading = false
      }
    },

    async activateAccount(isShortRegistration = true) {
      this.isTouched = true
      if (!this.validateSmsCode) return
      if (!isShortRegistration) {
        if (!this.validatePhone || !this.validateFullName || !this.validateUsersPassword) return
      }
      try {
        this.isAuthLoading = true
        const phone = this.phone.replace(/[+() ]/g, '')
        const activationCode = this.smsCode
        await this.wrapAndHandleRequest(() => Api.activation({ phone, activationCode }))
        const routerData = { name: 'auth.login' }
        if (!isShortRegistration) {
          routerData.query = { activated: 'true' }
        }
        await this.$router.push(routerData)
      } finally {
        this.isAuthLoading = false
      }
    },

    async handleLogin() {
      this.isTouched = true
      if (!this.validateData()) return
      try {
        this.isAuthLoading = true
        const username = btoa(this.phone)
        const password = btoa(this.password)
        await this.wrapAndHandleRequest(() => Api.login({ username, password }), false)
      } finally {
        this.isAuthLoading = false
      }
    },

    validateData() {
      return this.validatePhone && this.validatePassword
    },
  },
}
</script>
